/* React Typescript component YouTubePlayer for embedding YouTube video and starting playback from a certain timestamp. video_id and time_sec are props. When YouTube API loads, start playback. Use hooks. */

import React, { useEffect, useState } from 'react';

import YouTube, {YouTubeProps} from 'react-youtube';

interface YouTubePlayerProps {
    video_id: string;
    time_sec: number;
}

const YouTubePlayer: React.FC<YouTubePlayerProps> = (props) => {
    const [player, setPlayer] = useState<any>(null);
    const [video_id, setVideoId] = useState<string>(props.video_id);
    const [time_sec, setTimeSec] = useState<number>(props.time_sec);

    useEffect(() => {
        if (player) {
            player.loadVideoById({
                videoId: video_id,
                startSeconds: time_sec,
            });
        }
    }, [player, video_id, time_sec]);

    const opts = {
        height: '390',
        width: '640',
        playerVars: {
            autoplay: 1,
        },
    };

    const onPlayerReady: YouTubeProps['onReady'] = (event) => {
        event.target.seekTo(time_sec);
        event.target.playVideo();
        // setPlayer(event.target);
    }

    return (
        <YouTube
            videoId={video_id}
            opts={opts}
            onReady={onPlayerReady}
        />
    );
};

export default YouTubePlayer;