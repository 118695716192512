import {useEffect, useState} from "react";

export function useDebounce(value: string, delay: number) {
    // State and setters for debounced value
    const [debouncedValue, setDebouncedValue] = useState(value);
    useEffect(
        () => {
            // Update debounced value after delay
            const handler = setTimeout(() => {
                setDebouncedValue(value);
            }, delay);
            // Cancel the timeout if value changes (also on delay change or unmount)
            // This is how we prevent debounced value from updating if value is changed ...
            // .. within the delay period. Timeout gets cleared and restarted.
            return () => {
                clearTimeout(handler);
            };
        },
        [value, delay] // Only re-call effect if value or delay changes
    );
    return debouncedValue;
}

export function format_time(time_sec: number) {

    let hours = Math.floor(time_sec / 3600);

    let minutes = Math.floor((time_sec - (hours * 3600)) / 60);

    let seconds = Math.round(time_sec - (hours * 3600) - (minutes * 60));

    let time = "";

    if (hours != 0) {
        time = hours + ":";
    }
    if (minutes != 0 || time !== "") {
        let minutes_s = (minutes < 10 && time !== "") ? "0" + minutes : String(minutes);
        time += minutes_s + ":";
    }
    if (time === "") {
        time = "0:" + ((seconds < 10) ? "0" + seconds : String(seconds));
    } else {
        time += (seconds < 10) ? "0" + seconds : String(seconds);
    }

    return time;
}