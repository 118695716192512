import {ChangeEvent, useEffect, useState, useCallback} from "react";
import {format_time, useDebounce} from "./utils";
import YouTubePlayer from "./YouTubePlayer";

interface Result {
    video_id: string;
    frame_time: number;
    distance: number;
}

interface SearchResponse {
    error?: string;
    results?: Result[];
}

interface SearchResultsProps {
    results: Result[];
    isLoading: boolean;
}

const SearchResults = ({results}: SearchResultsProps) => {
    const [selectedVideo, setSelectedVideo] = useState<string>();
    const [selectedTime, setSelectedTime] = useState<number>(0);
    const [isSelectedVideoExpanded, setSelectedVideoExpanded] = useState<boolean>(false);

    const defaultResultsPerVideo = 5;

    const onThumbnailClick = (videoId: string, time: number) => {
        setSelectedVideo(videoId);
        setSelectedTime(time);
    };

    const renderVideo = () => {
        if (selectedVideo) {
            return (
                <div className="fixed top-0 right-0 draggable">
                    <div className="close-button" onClick={() => setSelectedVideo("")}>X</div>
                    <YouTubePlayer video_id={selectedVideo} time_sec={selectedTime}/>
                </div>
            );
        }
    };

    const renderThumbnails = (videoId: string, results: Result[]) => {
        return results.map(({frame_time, distance}, index) => {
            if ((!isSelectedVideoExpanded || selectedVideo != videoId) && index > defaultResultsPerVideo) {
                return null;
            }
            const url = `https://img.youtube.com/vi/${videoId}/default.jpg`;
            return (
                <div>
                    {selectedVideo == videoId && selectedTime == frame_time && renderVideo()}
                    <div className={`thumbnail-container mx-2 ${selectedVideo == videoId && selectedTime == frame_time ? "bg-red-500" : ""}`} key={videoId + "_" + frame_time}
                         onClick={() => onThumbnailClick(videoId, frame_time)}>
                        <img className="thumbnail" src={url} alt="thumbnail"/>
                        <div className="offset">{format_time(frame_time)}</div>
                    </div>
                </div>
            );
        });
    };

    const renderResults = () => {
        if (results) {
            const groupedResults = results.sort((a, b) => b.distance - a.distance).reduce((acc: { [key: string]: Result[] }, result) => {
                const {video_id} = result;
                if (!acc[video_id]) {
                    acc[video_id] = [];
                }
                acc[video_id].push(result);
                return acc;
            }, {});
            return Object.keys(groupedResults).sort((a, b) => groupedResults[b][0].distance - groupedResults[a][0].distance).map((videoId) => {
                return (
                    <div className="m-8 flex flex-row clear-both">
                        {renderThumbnails(videoId, groupedResults[videoId])}
                        {groupedResults[videoId].length > defaultResultsPerVideo && (!isSelectedVideoExpanded || videoId != selectedVideo) &&
                            <a className="cursor-pointer" onClick={() => {
                                setSelectedVideoExpanded(true);
                                setSelectedVideo(videoId);
                            }}>
                              Show {groupedResults[videoId].length - defaultResultsPerVideo} more
                            </a>}
                    </div>
                );
            });
        }
    };

    return (
        <div className="justify-center">
            {renderResults()}
        </div>
    );
};

const SearchField = () => {
    const [query, setQuery] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);
    const [results, setResults] = useState(null);

    const debouncedQuery = useDebounce(query, 750);

    useEffect(() => {
        if (debouncedQuery) {
            setIsLoading(true);
            setError(null);
            setResults(null);
            fetch(`http://search.video.tuttitempi.com:8080/search?query=${debouncedQuery}&limit=100`)
                .then((response) => response.json())
                .then((data) => {
                    setIsLoading(false);
                    if (data.error) {
                        setError(data.error);
                    } else {
                        setResults(data.results);
                    }
                })
                .catch((error) => {
                    setIsLoading(false);
                    setError(error.message);
                });
        }
    }, [debouncedQuery]);

    const renderError = () => {
        if (error) {
            return <div className="error">{error}</div>;
        }
    };

    const onChange = (event: ChangeEvent<HTMLInputElement>) => {
        setQuery(event.target.value);
    };

    return (
        <div className="flex flex-col items-center justify-center">
            <input className="border-2 border-gray-400 rounded-lg p-2 w-64 m-8" type="text"
                   placeholder="Enter visual search query" value={query} onChange={onChange}/>
            {renderError()}
            {results && <SearchResults isLoading={isLoading} results={results}/>}
        </div>
    );
};

const App = () => {
    return (
        <div className="app flex flex-col items-center">
            <SearchField/>
        </div>
    );
};

export default App;
